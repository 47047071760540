import {FC, ReactElement} from "react";

type Alignment = 'left' | 'right';

interface ClownProps {
  alignment: Alignment;
}

export const Clown: FC<ClownProps> = ({ alignment }): ReactElement => {

  return (
    <div style={{
      transform: alignment === 'left' ? 'scaleX(-1)' : 'scaleX(1)',
      gridArea: alignment === 'left' ? 'left' : 'right',
      zIndex: '2',
      padding: '10px 0 0 10px',
      marginTop: '65px',
      height: '310px',
    }}
    >
      <img
        style={{
          margin: '0',
          padding: '0',
        }}
        src="images/clown.png"
        alt="Clown"
      />
    </div>
  );

}
