import {FC, ReactElement, useEffect, useState} from "react";

import styles from './Dots.module.css';

type Dot = {
  color: string;
  x: number;
  y: number;
  id: number;
};

interface DotsProps {
  pointsHigh?: number;
}

export const Dots: FC<DotsProps> = ({ pointsHigh=375 }): ReactElement => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dots, setDots] = useState<Dot[]>([]);

  const color: string[] = ['red', 'blue', 'yellow', 'lightGreen', 'green', 'orange', 'violet'];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const pointsNumber: number = windowWidth / 8;

    const generatePoints = (): Dot[] => {
      const newDots: Dot[] = [];
      for (let i = 0; i < pointsNumber; i++) {
        const randomColor: string = color[Math.floor(Math.random() * color.length)];
        const randomX: number = Math.floor(Math.random() * (windowWidth - 13));
        const randomY: number = Math.floor(Math.random() * pointsHigh);
        newDots.push({ color: randomColor, x: randomX, y: randomY, id: i });
      }
      return newDots;
    };

    setDots(generatePoints());
  }, [windowWidth, pointsHigh]);

  return (
    <div className={styles.dotContainer}>
      {dots.map((dot) => (
        <div
          key={dot.id}
          className={`${styles.dot} ${styles[dot.color.replace('-', '')]}`}
          style={{left: `${dot.x}px`, top: `${dot.y}px`}}
        ></div>
      ))}
    </div>
  );

}