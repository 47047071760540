import { FC, ReactElement } from "react";

interface HeaderProps {
  isMobile: boolean;
}

export const Nav: FC<HeaderProps> = (isMobile ): ReactElement => {

  return (
    <div style={{
      gridArea: 'nav',
      width: '100%',
      height: '120px',
      backgroundColor: 'var(--blue)',
    }}>
      <h2 style={{
        textAlign: 'center',
        color: 'var(--white)',
        margin: '5px',
        padding: '0',
        fontFamily: 'Agbalumo',
        fontSize: '32px',
      }}>Festausschuss Badorf - Eckdorfer Karnevalsfreunde e.V.</h2>
    </div>
  );

}