import {FC, ReactElement} from "react";

export const TitleImg: FC = (): ReactElement => {

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
    }}>
      <img style={{
        maxWidth: "var(--title-img-width)",
        maxHeight: 'var(--title-img-max-height)',
        zIndex: '2',
      }} src="images/kumm-loss-mer-fiere.jpg" alt="Kumm-loss-mer-fiere"
      />
    </div>
  );

}