// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* colors */
  --background-color: #FFFCD8;

  --red: #ff0000;
  --blue: #0000ff;
  --yellow: #ffe884;
  --light-green: #73ff00;
  --green: #277137;
  --orange: #ff8f49;
  --violet: #e136ac;
  --white: #ffffff;

  --dot-diameter: 13px;
  --title-img-width: 80%;
  --title-img-max-height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2BAA2B;;EAE3B,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;;EAEhB,oBAAoB;EACpB,sBAAsB;EACtB,6BAA6B;AAC/B","sourcesContent":[":root {\n  /* colors */\n  --background-color: #FFFCD8;\n\n  --red: #ff0000;\n  --blue: #0000ff;\n  --yellow: #ffe884;\n  --light-green: #73ff00;\n  --green: #277137;\n  --orange: #ff8f49;\n  --violet: #e136ac;\n  --white: #ffffff;\n\n  --dot-diameter: 13px;\n  --title-img-width: 80%;\n  --title-img-max-height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
