import {FC, ReactElement, useEffect, useState} from "react";
import {Title} from "./Title";
import {Clown} from "../components/Clown";
import {Nav} from "./Nav";
import {Main} from "./Main";

export const Base: FC = (): ReactElement => {

  const mobileWidth = 845;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    /*<div className={isMobile ? 'grid-mobile' : 'grid-pc'}>*/
    <div style={{
      display: 'grid',
      gridTemplateColumns: isMobile ? 'auto' : '129px auto 129px',
      gridTemplateAreas: isMobile ? '"title" "nav" "main"' : '"left title right" "nav nav nav" "main main main"',
      columnGap: isMobile ? "" : '10px',
    }}>

      {isMobile ? "" : <Clown alignment={"left"} />}
      <Title isMobile={isMobile} />
      {isMobile ? "" : <Clown alignment={"right"} />}
      <Nav isMobile={isMobile} />
      <Main />

    </div>
  );

}