import {FC, ReactElement} from "react";

const balloons = [
  {"id": 1, "club": "K.I.G. De Pappnase", "color": "#00a4d8", "img": "129.png"},
  {"id": 2, "club": "K.I.G Die Namenlosen", "color": "#38aa83", "img": "131.png"},
  {"id": 3, "club": "KIG Badorfer Wildschweine", "color": "#0065ff", "img": "125.png"},
  {"id": 4, "club": "KIG SC Phantasialand", "color": "#ffe884", "img": "126.png"},
  {"id": 5, "club": "Musik- und Showband Weiß-Blau Brühl", "color": "#959595", "img": "127.png"},
  {"id": 6, "club": "K.I.G. Millennium e.V.", "color": "#ff8f49", "img": "128.png"},
  {"id": 7, "club": "Dorfgemeinschaft Badorf-Eckdorf e.V.", "color": "#ff0000", "img": "124.jpg"},
  {"id": 8, "club": "K.I.G. Jeck un Joot drupp", "color": "#74309e", "img": "133.png"},
  {"id": 9, "club": "K.I.G. De Schwaadlappe", "color": "#2f9a0f", "img": "132.png"},
  {"id": 10, "club": "K.I.G. Höppemötzje", "color": "#c82384", "img": "134.png"},
  {"id": 11, "club": "KG Leevje & Suffkrade", "color": "#154a0e", "img": "130.png"},
]

export const Clubs: FC = (): ReactElement => {

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'auto',
      }}
    >
      {balloons.map((balloon, index) => (
        <div>
          <div
            style={{
              width: '75px',
              height: '130px',
              backgroundColor: balloon.color,
              borderRadius: '50%',
              border: 'solid #000000',
              overflow: 'hidden',
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
              src={`images/clubs/${balloon.img}`}
              alt="Vereinslogo"
            />
          </div>
        </div>
      ))}
    </div>

  );

}