import {FC, ReactElement} from 'react';

import {Base} from "./views/Base";

export const App: FC = (): ReactElement => {

  return (
    <Base />
  );

}
