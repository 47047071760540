import { FC, ReactElement } from "react";

import { Dots } from "../components/Dots";
import { TitleImg } from "../components/TitleImg";
import {Clubs} from "../components/Clubs";

interface HeaderProps {
  isMobile: boolean;
}

export const Title: FC<HeaderProps> = (isMobile ): ReactElement => {

  return (
    <header style={{
      gridArea: 'title',
    }}>
      <TitleImg />
      <Clubs />
      <Dots />
    </header>
  );

}