import { FC, ReactElement } from "react";
import {Clubs} from "../components/Clubs";

export const Main: FC = (): ReactElement => {

  return (
    <div style={{
      gridArea: 'main',
      width: '80%',
      height: '300px',
    }}>
    </div>
  );

}