// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dots_dotContainer__WsX62 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
}

.Dots_dot__V7-Ei {
  position: absolute;
  width: var(--dot-diameter);
  height: var(--dot-diameter);
  border-radius: 50%;
}

.Dots_red__VXIZR {
  background-color: var(--red);
}

.Dots_blue__-LIdJ {
  background-color: var(--blue);
}

.Dots_yellow__M0y6o {
  background-color: var(--yellow);
}

.Dots_lightGreen__MFoYg {
  background-color: var(--light-green);
}

.Dots_green__qEisr {
  background-color: var(--green);
}

.Dots_orange__0CVD8 {
  background-color: var(--orange);
}

.Dots_violet__9s7LH {
  background-color: var(--violet);
}
`, "",{"version":3,"sources":["webpack://./src/components/Dots.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".dotContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 120px;\n}\n\n.dot {\n  position: absolute;\n  width: var(--dot-diameter);\n  height: var(--dot-diameter);\n  border-radius: 50%;\n}\n\n.red {\n  background-color: var(--red);\n}\n\n.blue {\n  background-color: var(--blue);\n}\n\n.yellow {\n  background-color: var(--yellow);\n}\n\n.lightGreen {\n  background-color: var(--light-green);\n}\n\n.green {\n  background-color: var(--green);\n}\n\n.orange {\n  background-color: var(--orange);\n}\n\n.violet {\n  background-color: var(--violet);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dotContainer": `Dots_dotContainer__WsX62`,
	"dot": `Dots_dot__V7-Ei`,
	"red": `Dots_red__VXIZR`,
	"blue": `Dots_blue__-LIdJ`,
	"yellow": `Dots_yellow__M0y6o`,
	"lightGreen": `Dots_lightGreen__MFoYg`,
	"green": `Dots_green__qEisr`,
	"orange": `Dots_orange__0CVD8`,
	"violet": `Dots_violet__9s7LH`
};
export default ___CSS_LOADER_EXPORT___;
